<template>
  <div class="roomEquipment">
    <div class="nav" ref="nav">
      <div
        :class="['item', { active: currentIndex == index }]"
         :id="`item_${index}`"
        v-for="(item, index) in navList"
        :key="index"
        @click="handelChangeNav(item,index)"
      >
        <span> {{ item.label }} </span>
        <img v-if="currentIndex == index" src="./img/tab-icon.png" alt="" />
      </div>
    </div>
    <div class="model">
      <!-- <div class="img">
        <img src="./img/test.png" alt="" />
      </div> -->

      <iframe
        src="https://project.zhuneng.cn/d3m/#/s/a3eba1647f2a4915a9b1ff95328fe904"
        frameborder="0"
        name="myFrameName"
        id="myFrameId"
      >
      </iframe>
    </div>
    <div class="my-equipment">
      <div class="title">我的设备</div>
      <div class="list">
        <div class="item" v-for="item in 2" :key="item">
          <div class="item-t">
            <img src="./img/air.png" alt="" />
            <span>空调</span>
          </div>
          <div class="item-b">
            <p>
              <span>空调温度：</span>
              <span>26°C</span>
            </p>
            <p>
              <span>空调模式：</span>
              <span>制冷</span>
            </p>
            <p>
              <span>空调风量：</span>
              <span>三挡</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "roomEquipment",
  components: {},
  props: {},
  data() {
    return {
      currentIndex: 0,
      navList: [
        {
          label: "3号楼2503房间",
        },
        {
          label: "4号楼503房间",
        },
        {
          label: "5号楼502房间",
        },
        {
          label: "6号楼603房间",
        },
        {
          label: "7号楼708房间",
        },
        {
          label: "1号楼255503房间",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handelChangeNav(item,index){
      this.currentIndex=index
        const element = document.getElementById(`item_${index}`);
      const container = this.$refs.nav;
      const scrollLeftNum =
        element.offsetLeft -
        container.offsetWidth / 2 +
        element.offsetWidth / 2;
      container.scrollLeft = scrollLeftNum;
    }
  },
};
</script>

<style scoped lang="less">
.roomEquipment {
  width: 100%;
  height: 100vh;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 750px;
  box-sizing: border-box;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  .nav {
    padding: 48px 64px;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */

    .item {
      box-sizing: border-box;
      width: auto;
      flex-shrink: 0;
      text-align: center;
      position: relative;
      &.active {
        span {
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          line-height: 44px;
        }
      }
      &:not(:last-child) {
        margin-right: 68px;
      }
      span {
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }
      img {
        position: absolute;
        right: -22px;
        top: -12px;
        width: 28px;
        height: 26px;
      }
    }
  }
  .nav::-webkit-scrollbar {
    display: none;
  }

  .model {
    padding: 0 30px 30px 30px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    flex: 1;
    box-sizing: border-box;
    .img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 8px;
    }
  }
  .my-equipment {
    padding: 0 30px 30px 30px;
    margin-top: 48px;
    box-sizing: border-box;
    .title {
      margin-bottom: 20px;
      font-size: 32px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44p;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .item {
        flex: 1;
        background: #ffffff;
        box-shadow: 0px 6px 18px 0px rgba(231, 232, 238, 0.5);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 28px 28px 38px 28px;
        display: flex;
        flex-direction: column;
        .item-t {
          display: flex;
          align-items: center;
          margin-bottom: 46px;
          img {
            margin-right: 16px;
            width: 64px;
            height: 64px;
          }
          span {
            font-size: 28px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
        }
        .item-b {
          p {
            &:not(:last-child) {
              margin-bottom: 16px;
            }
            span {
              font-size: 28px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 40px;
              &:last-child {
                color: #fe4416;
              }
            }
          }
        }
      }
    }
  }
}
</style>
